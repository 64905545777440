import axios from 'axios';

function getUrlExtension(url: string): string {
    const urlWithoutParams = url.split('?')[0] ?? '';
    const match = /\.[0-9a-z]+$/i.exec(urlWithoutParams);
    return match ? match[0].slice(1) : '';
}

export async function immediatelyDownloadFile(url: string, filename: string): Promise<void> {
    const extension = getUrlExtension(url);

    const response = await axios.get<Blob>(url, {
        responseType: 'blob',
    });
    const binaryData = response.data;

    if (binaryData != null) {
        const blob = new Blob([binaryData]);
        startDownloadForBlob(filename, extension, blob);
    }
}

export function startDownloadForBlob(filename: string, extension: string, blob: Blob): void {
    const link = document.createElement('a');

    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = `${filename}.${extension}`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function startDownloadForBase64String(filename: string, extension: string, base64: string): void {
    const blob = base64toBlob(base64);
    startDownloadForBlob(filename, extension, blob);
}

export function base64toBlob(base64: string, sliceSize = 512): Blob {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays);
    return blob;
}
